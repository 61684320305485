import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { UserRepository, userStore } from './store/auth.repository';
import { User } from '../user/user.types';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _userStore = new UserRepository();
    private _socialAuthService = inject(SocialAuthService);



    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/auth/forgot-password', { email });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string, token: string): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/auth/reset-password', { password, token });
    }

    /**
     * Sign in
     *
     * @param credentials
     */

    setTokenLocalStorage(user: User, tokens: any) {
        if (user.isEmailVerified) {
            this._userStore.updateUser(user);
            this.accessToken = tokens.access.token;
            this._authenticated = true;
            this._userService.user = user;
        }
        console.log({ user, tokens });
        console.log(of({ user, tokens }));
        return of({ user, tokens })
    }

    generatePasskeyRegistrationOptions(serverShare, passkeyShare): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/passkey/generate-options', {
            serverShare, passkeyShare
        });
    }

    verifyPasskeyRegistrationOptions(response): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/passkey/verify-options', {
            response
        });
    }

    generateVerificationOptions(email): Observable<any> {
        return this._httpClient.get(environment.apiUrl + '/v1/passkey/generate-authentication-options?email=' + email);
    }

    fetchGithubLoginUrl(): Observable<any> {
        return this._httpClient.get(environment.apiUrl + '/v1/auth/url-github');
    }


    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(environment.apiUrl + '/v1/auth/login', credentials).pipe(
            switchMap((response: any) => {
                if (response.user.isEmailVerified) {
                    this._userStore.updateUser(response.user);

                    this.accessToken = response.tokens.access.token;
                    this._authenticated = true;
                    this._userService.user = response.user;
                }
                console.log(response);
                console.log(of(response));
                return of(response)
            }),
        );
    }

    /**
     * Sign in using the Google access token
     */

    signInUsingGoogleToken(token: string): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/auth/verify-google', { token });
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        this._socialAuthService.signOut();
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string }): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/auth/register', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (this.accessToken) {
            return of(true);
        }
        return of(false);

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.accessToken) )
        // {
        //     return of(false);
        // }

        // If the access token exists, and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }
}
